import { Box } from '@mui/material';
import React from 'react';

export function NewPageSection({ title, children, color }) {
  return (
    <div>
      <Box component="h2" mb="12px" sx={{ color }}>
        {title}
      </Box>
      {children}
    </div>
  );
}
