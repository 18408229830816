import * as React from 'react';
import Page from './page';
import { PageTitle } from '../../components/page-title';
import { COLORS } from '../../constants';
import { Spacing } from '../../components/spacing';
import { NewPageSection } from '../../components/new-page-section';
import { Text } from '../../components/text';

export function Background({ refElement }) {
  return (
    <Page id="education" refElement={refElement}>
      <Spacing size="large">
        <PageTitle color={COLORS.background}>Education</PageTitle>
      </Spacing>

      <Spacing size="large">
        <NewPageSection
          title="City, University of London"
          color={COLORS.background}
        >
          <Spacing size="small">
            <p>
              <Text emphasis="black">MSc HCI Design, 2021-2023</Text>
            </p>
            <p>*Elected class representative</p>
          </Spacing>
          Courses:
          <ul>
            <li>Interaction Design</li>
            <li>Inclusive Design</li>
            <li>Information Architecture</li>
            <li>Research Methods & Professional Issues</li>
            <li>Creativity in Design</li>
            <li>Evaluating Interactive Systems</li>
            <li>Understanding User Interactions</li>
            <li>Web Applications Development</li>
          </ul>
        </NewPageSection>
      </Spacing>

      <Spacing size="large">
        <NewPageSection title="Furman University" color={COLORS.background}>
          <Spacing size="small">
            <p>
              <Text emphasis="black">
                Bachelor’s in Sociology and Communication Studies, 2014-2018
              </Text>
            </p>
            <p>
              <Text italics>Focus in Business & Marketing</Text>
            </p>
            <p>*Magna Cum Laude</p>
          </Spacing>
        </NewPageSection>
        Honors:
        <ul>
          <li>Endel Medal for Excellence in Rhetoric and Oratory​ - 2018</li>
          <li>Lambda Pi Eta Communications Honors Society​ - 2017</li>
          <li>Alpha Kappa Delta Sociology Honors Society - ​2017</li>
          <li>Phi Eta Sigma Honors Society - ​2015</li>
          <li>Dean’s List - 7 semesters</li>
        </ul>
      </Spacing>
    </Page>
  );
}

export default Background;
